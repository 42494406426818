import axios from 'axios'
import getSession from '@sublayer/passport-components/lib/getSession'

const api = axios.create({
    baseURL: window._env_.REACT_APP_API_URL
})

api.interceptors.request.use(function (config) {

    const session = getSession()

    config.headers = config.headers || {}

    if (session) {
        config.headers['Authorization'] = `Bearer ${session}`
    }

    return config
})

export default api