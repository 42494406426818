import api from './api'
import { getHistory } from './history'

const hooks = {
    "button.onClick/Workflow.sendTestEvent": async (e, { record, setLoading, label, setLabel }) => {

        setLoading(true)
        setLabel('Aan het versturen...')

        await api.request({
            method: 'POST',
            url: '/graphql',
            data: {
                query: `
                    mutation sendTestEvent($id: ID!) {
                        sendTestEvent(id: $id)
                    }
                `,
                variables: {
                    id: record.id
                }
            }
        })

        setLoading(false)
        setLabel(label)
    },
    "HasMany.onRequest": async params => {

    },
    "HasOne.onRequest": async params => {

    },
    "Table.onRecordClick": async ({ modelId, recordId }) => {

        getHistory().push(`/explorer/${modelId}/${recordId}`)
    },
    "relationship.onRecordClick": async ({ modelId, recordId }) => {

        getHistory().push(`/explorer/${modelId}/${recordId}`)
    }
}

export default hooks